<footer>
    <div class="col-lg-10 col-lg-offset-1 text-center">
        <h3>
            <strong><b class="orange-text">Katiso Engineering</b></strong>
        </h3>
        <p>Building A6<br />Automotive Supplier Park<br />30 Helium Rd<br />Rosslyn, Pretoria<br />0200</p>
        <ul class="list-unstyled">
            <li><i class="fa fa-phone fa-fw"></i> +(27)12 564 5082</li>
            <li>
                <i class="fa fa-envelope-o fa-fw"></i><a href="mailto:info@katiso-engineering.co.za"><img src="./assets/page-content/home/email_address.png" alt="katiso engineering email address" /></a>
            </li>
        </ul>
        <br>
        <ul class="list-inline share-pages">
            <li>
                <a href="https://www.facebook.com/Katiso-Engineering-532161903782241/" target="_blank" title="Katiso Engineering Facebook Page"><i class="fa fa-facebook fa-fw fa-3x"></i></a>
            </li>
            <li>
                <a href="https://twitter.com/katisoengineer" target="_blank" title="Katiso Engineering Twitter Page"><i class="fa fa-twitter fa-fw fa-3x"></i></a>
            </li>
            <li>
                <a href="https://plus.google.com/108401295281165054907" target="_blank" title="Katiso Engineering Google+ Page"><i class="fa fa-google-plus fa-fw fa-3x"></i></a>
            </li>
        </ul>
        <hr class="small">
        <share-container
        direction="horizontal"
        [expandable]="true"
        [textEnabled]="true">
        </share-container>
        <p class="text-muted"><a href="sitemap.xml" title="Katiso Engineering sitemap">Sitemap</a> | Copyright &copy; <b class="orange-text">Katiso Engineering</b> 2017</p>
    </div>
</footer>