<div class="main-container" [ngStyle]="backGroundObj">
    <nav class="navbar navbar-default navbar-fixed-top">
        <div class="container-fluid">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand" routerLink="/home">Katiso</a>
            </div>
            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav">
                    <li routerLinkActive="active"><a routerLink="/home">Home</a></li>
                    <!-- <li routerLinkActive="active"><a routerLink="/partners">Partners</a></li> -->
                    <!-- <a routerLinkActive="active"><a routerLink="/services">Services</a></a> -->
                    <li class="dropdown">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services <span class="caret"></span></a>
                        <ul class="dropdown-menu">
                            <li><a routerLink="/services/automation">Automation</a></li>
                            <li><a routerLink="/services/engineering">Engineering</a></li>
                            <li><a routerLink="/services/programming">Programming</a></li>
                        </ul>
                    </li>
                </ul>
                <ul class="nav navbar-nav navbar-right">
                    <li routerLinkActive="active"><a routerLink="/about-us">About Us</a></li>
                    <li routerLinkActive="active"><a routerLink="/contact-us">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="body-container">
        <router-outlet></router-outlet>
        <kts-footer></kts-footer>
    </div>
</div>