<div class="content-container">
    <section id="about" class="about">
        <div class="col-xs-10 col-xs-offset-1">
            <h2>A Top Contender in South Africa's Automation Industry</h2>
            <p class="lead">We provide turnkey automation solutions & services, specialising in automotive, food & beverage, and tobacco industries.</p>
        </div>
        <div class="col-xs-12 hidden-sm">
            <div id="homeCarousel" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->
                <ol class="carousel-indicators">
                    <li data-target="#homeCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#homeCarousel" data-slide-to="1"></li>
                    <li data-target="#homeCarousel" data-slide-to="2"></li>
                </ol>

                <!-- Wrapper for slides -->
                <div class="carousel-inner">
                    <div class="item active" style=" background: url('./assets/page-content/home/slide-1.jpg')">
                        <div class="carousel-caption">
                            <h1>Focused</h1>
                            <p>Throughout all the phases from planning through to delivery we are focused on your business requirements.</p>
                        </div>
                    </div>
                    <div class="item" style=" background: url('./assets/page-content/home/slide-2.jpg')">
                        <div class="carousel-caption">
                            <h1>The one that stands out</h1>
                            <p>Through excelent services and products we are striving to become the best in our market space.</p>
                        </div>
                    </div>
                    <div class="item" style=" background: url('./assets/page-content/home/slide-3.jpg')">
                        <div class="carousel-caption">
                            <h1>Skilled</h1>
                            <p>At Katiso Engineering we are driven by our well trained and highly skilled staff. </p>
                        </div>
                    </div>
                </div>

                <!-- Left and right controls -->
                <a class="left carousel-control" href="#homeCarousel" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#homeCarousel" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </section>
    <section id="services" class="services">
        <div class="row text-center">
            <div class="col-lg-10 col-lg-offset-1">
                <h2>Our Services</h2>
                <div class="row">
                    <div class="col-sm-4 col-xs-12">
                        <div class="service-item">
                            <span class="fa-stack fa-4x">
                                <i class="fa fa-circle fa-stack-2x"></i>
                                <i class="fa fa-cogs fa-stack-1x text-primary"></i>
                            </span>
                            <h3>
                                <strong>Automation & Systems</strong>
                            </h3>
                            <p class="text-justify">Katiso is capable of full, in-house PLM development & improvement of new and existing production systems, all Turn-Key.</p>
                            <a routerLink="/services/automation" class="btn btn-light">Learn More</a>
                        </div>
                    </div>
                    <div class="col-sm-8 col-xs-12">
                        <img class="img-responsive" src="./assets/page-content/home/automation.jpg" alt="Katiso Engineering | Automation & Systems"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-sm-push-8 col-xs-12 ">
                        <div class="service-item">
                            <span class="fa-stack fa-4x">
                                    <i class="fa fa-circle fa-stack-2x"></i>
                                    <i class="fa fa-terminal fa-stack-1x text-primary"></i>
                                </span>
                            <h3>
                                <strong>Programming & Software</strong>
                            </h3>
                            <p class="text-justify">We are able to provide support to our clients by using a wide variety of software platforms. We tailor our services to suit our clients needs.</p>
                            <a routerLink="/services/programming" class="btn btn-light">Learn More</a>
                        </div>
                    </div>
                    <div class="col-sm-8 col-sm-pull-4">
                        <img class="img-responsive" src="./assets/page-content/home/programing.jpg" alt="Katiso Engineering | Programming and Software Calculations"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4 col-xs-12">
                        <div class="service-item">
                            <span class="fa-stack fa-4x">
                                <i class="fa fa-circle fa-stack-2x"></i>
                                <i class=" fa fa-wrench fa-stack-1x text-primary"></i>
                            </span>
                            <h3>
                                <strong>Engineering</strong>
                            </h3>
                            <p class="text-justify">We provide our clients with professional product design and development, engineering consulting, support and training, and more.</p>
                            <a routerLink="/services/engineering" class="btn btn-light">Learn More</a>
                        </div>
                    </div>
                    <div class="col-sm-8 col-xs-12">
                        <img class="img-responsive" src="./assets/page-content/home/enginering.jpg" alt="Katiso Engineering | Engineering Wiring and Tools" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <div class="bar"></div>
    <section id="portfolio" class="portfolio">
        <div class="row">
            <div class="col-lg-10 col-lg-offset-1 text-center">
                <h2>Our Work</h2>
                <div id="workCarousel" class="carousel slide" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#workCarousel" data-slide-to="0" class="active"></li>
                        <li data-target="#workCarousel" data-slide-to="1"></li>
                        <li data-target="#workCarousel" data-slide-to="2"></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="item active">
                            <img src="http://via.placeholder.com/1900x1024" alt="automation">
                            <div class="carousel-caption">
                                <h1>We work hard</h1>
                                <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                                <p><a class="btn btn-sm btn-warning" routerLink="/services/automation" role="button">Automation</a></p>
                            </div>
                        </div>

                        <div class="item">
                            <img src="http://via.placeholder.com/1900x1024" alt="programming">
                            <div class="carousel-caption">
                                <h1>We play hard</h1>
                                <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                                <p><a class="btn btn-sm btn-warning" routerLink="/services/programming" role="button">Programming & Software</a></p>
                            </div>
                        </div>

                        <div class="item">
                            <img src="http://via.placeholder.com/1900x1024" alt="engineering">
                            <div class="carousel-caption">
                                <h1>We think clearly</h1>
                                <p>Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus. Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                                <p><a class="btn btn-sm btn-warning" routerLink="/services/engineering" role="button">Engineering</a></p>
                            </div>
                        </div>
                    </div>
                    <a class="left carousel-control" href="#workCarousel" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#workCarousel" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </section> -->
</div>