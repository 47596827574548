<div>
    <h1 class="text-center">About Katiso</h1>
    <div class="col-md-4 col-md-offset-4 col-xs-12">
        <img src="./assets/logo.png" class="img-responsive img-rounded center-block" alt="Katiso Engineering Large Logo" />
    </div>
    <div class="col-xs-12">
        <div class="col-sm-5 col-xs-12">
            <h2 class="text-center">Our Vision</h2>
            <p>To be the leading provider of services and automation solutions in our field of expertise.</p>
            <h2 class="text-center">Our Mission Statement</h2>
            <p>At <b class="katiso-brand">Katiso Engineering</b>, we strive towards a single goal to improve our customers’ productivity with the best industrial automation solutions, reliability and services.</p>
        </div>
        <div class="col-sm-7 col-xs-12">
            <h2 class="text-center">History</h2>
            <p><b class="katiso-brand">Katiso Engineering</b> was established in 2017. Although it is a newly founded company, <b class="katiso-brand">Katiso Engineering</b>'s personnel has an accumulative 177 years technical experience in the automotive and automation industry.</p>
            <p>Our turnkey solutions & services span a broad range of industries, such as the automotive, beverage and packaging industries, as well as other industrial sectors.</p>
            <p>Our core business is automation technology for the automotive industry. Our comprehensive experience gained both locally and abroad, allows us to provide cost-effective solutions uniquely tailored to our clients' needs. Our projecting capability encompasses from conceptual to final design, manufacture, assembly, software engineering, commissioning, training and support.</p>
        </div>
    </div>
</div>
<section class="col-xs-12">
    <h2 class="text-center">Our Family, Your Family</h2>
    <hr class="small" />
    <div class="col-md-push-1 col-md-10 col-xs-12">
        <p>Although newly established, the <b class="katiso-brand">Katiso</b> family is proud to consist of over 20 highly skilled and innovative engineering professionals.</p>
        <p>The <b class="katiso-brand">Katiso</b> family preserves its flexibility by evolving to suite our clients' needs. We pride ourselves in working with cutting edge technologies to provide you with elegant and innovative solutions whilst maintaining a professional level of service and quality.</p>
        <p>We believe in developing lasting relationships with our clients and partners, and revolutionising the automation industry.</p>
    </div>
    <div class="col-md-push-1 col-md-10 col-xs-12">
        <img src="./assets/page-content/about-us/family.jpg" class="img-responsive img-rounded center-block" alt="Katiso Engineering Family | Doing Business | Handshake" />
        <!-- <div class="col-md-6 col-md-6 col-xs-12">
            <img src="http://via.placeholder.com/500x250" class="img-responsive img-rounded center-block" alt="Katiso Engineering Employee Growth" />
        </div> -->
    </div>
</section>
<section class="col-xs-12">
    <h2 class="text-center">Location</h2>
    <hr class="small" />
    <div class="col-md-push-1 col-md-10 col-xs-12">
        <p><b class="katiso-brand">Katiso Engineering</b>'s head office is situated in the heart of the high veld. Gauteng, Pretoria.</p>
        <p>Katiso is flexible, ever expanding and adapting its capabilities to suit our clients' needs. In order to centralise our operations, our recently acquired design & manufacturing department will soon relocate to a new facility, located in the Automotive Industrial Area of Rosslyn Pretoria.</p>
    </div>
</section>