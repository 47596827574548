<section id="about" class="about">
    <div class="col-sm-6 col-xs-12 col-sm-push-6">
        <h2 class="text-center">Contact Form</h2>
        <div class="panel panel-info">
            <div class="panel-heading">
                <h3 id="myModalLabel" class="panel-title">Email us</h3>
            </div>
            <div class="panel-body">
                <form class="form-horizontal col-sm-12" (submit)="sendEmail(message)">
                    <div class="form-group">
                        <label><span class="glyphicon glyphicon-user"></span> Name</label>
                        <input class="form-control required" [(ngModel)]="message.name" placeholder="Your name" data-placement="top" data-trigger="manual" data-content="Must be at least 3 characters long, and must only contain letters." type="text" name="name" required>
                    </div>
                    <div class="form-group">
                        <label><span class="glyphicon glyphicon-envelope"></span> E-Mail</label>
                        <input class="form-control email" [(ngModel)]="message.email" placeholder="email@example.com (so that we can contact you)" data-placement="top" data-trigger="manual" data-content="Must be a valid e-mail address (user@gmail.com)" type="email" name="email"
                               required>
                    </div>
                    <div class="form-group">
                        <label><span class="glyphicon glyphicon-earphone"></span> Phone</label>
                        <input class="form-control phone" [(ngModel)]="message.phone" placeholder="999-999-9999" data-placement="top" data-trigger="manual" data-content="Must be a valid phone number (999-999-9999)" type="text" name="phone">
                    </div>
                    <div class="form-group">
                        <label><span class="glyphicon glyphicon-pencil"></span> Message</label>
                        <textarea rows="6" class="form-control" placeholder="Add your message here" data-placement="top" data-trigger="manual" name="message" required [(ngModel)]="message.message"></textarea>
                    </div>
                    <p class="hiddenitem"> leave this empty:<input type="text" name="lastthing" value="x" /></p>
                    <div style="margin-bottom:-15px">
                        <div class="form-group">
                            <button type="submit" [disabled]="isValid() || submitted" class="btn btn-warning pull-right" ><!--(submit)="onSubmit()">-->
                                <i class="fa" [class.fa-paper-plane]="!submitted" [class.fa-spin]="submitted" [class.fa-spinner]="submitted"></i> Send{{submitted?'ing':''}}
                            </button>
                            <p class="help-block pull-left text-danger hide" id="form-error">&nbsp; The form is not valid. </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-xs-12 col-sm-pull-6">
        <h2 class="text-center">Our Location</h2>
        <p class="lead text-justify">
            Katiso Engineering South Africa’s head office is situated in the heart of the high veld. Gauteng, Pretoria.
        </p>
        <p class="lead text-justify">
            Katiso is flexible, ever expanding and adapting its capabilities to suit our clients' needs. In order to centralise our operations, our recently acquired design & manufacturing department will soon relocate to a new facility, located in the Automotive Industrial Area of Rosslyn Pretoria.
        </p>
        <p class="lead text-justify">
            Our current location, Automotive Supplier Park, is home to some of the international tier 1 automotive suppliers such as Lear Corporation and Faurecia. This location enables us to be close to some of our bigger automotive clients, namely, BMW, Nissan and Ford, as well as some of our bigger non-automotive clients, namely SAB (South African Breweries).
        </p>
        <p class="lead text-left">
            <strong>Head Office Address:</strong><br />Building A6<br />Automotive Supplier Park<br />30 Helium Rd<br />Rosslyn, Pretoria<br />0200
        </p>
    </div>
    <div class="col-xs-12 ">
        <div class="map " [class.busy-loading-map]="!doneLoadingMap">
            <div class="embed-responsive embed-responsive-4by3 ">
                <iframe (load)="doneLoadingMap = true" width="600" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm8RoH07Qvx4RDfjJDxrozWM&key=AIzaSyCISshv8lcsVqIWwNS7Q-Zcriy_QBhbQgg" allowfullscreen>
                  </iframe>
            </div>
        </div>
        <div class="map loading-map" *ngIf="!doneLoadingMap">
            <div id="loading_container">
                <div class="cube-wrapper">
                    <div class="cube-folding">
                        <span class="leaf1"></span>
                        <span class="leaf2"></span>
                        <span class="leaf3"></span>
                        <span class="leaf4"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>